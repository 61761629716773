import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation, useQuery } from "@tanstack/react-query";
import { Flex } from "antd/lib";
import { Button, Card, Table } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";
import request from "../../../libs/request";

const PipelinesListPage = () => {
  const navigate = useNavigate();
  const { orgId } = useParams();

  const pipelines = useQuery({
    queryKey: ["dashboard", orgId, "pipelines"],
    queryFn: async () => {
      const { data } = await request.get(`/orgs/${orgId}/pipelines`);
      return data.pipelines;
    },
  });

  // const onDeletePipeline = useMutation((pipelineId) =>
  //   DashboardApis.pipelinesDelete({ orgId, pipelineId }),
  // );

  return (
    <div class="page">
      <PageHeader
        title="Pipelines"
        onBack={() => navigate(-1)}
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate("create")}
          >
            Create New
          </Button>
        }
      />

      <Table
        loading={pipelines.isLoading}
        dataSource={pipelines.data}
        columns={[
          { title: "Name", dataIndex: "name" },
          {
            title: "Created At",
            render: (row) =>
              moment(row.createdAt).format("hh:mm:ssA YYYY-MM-DD"),
          },
          {
            title: "Stages",
            render: (row) => row.stages.map((entry) => entry.name).join(" > "),
          },
          {
            title: "Actions",
            render: (row) => (
              <Flex gap={10}>
                <Button onClick={() => navigate(`edit/${row.id}`)} size="small">
                  Edit
                </Button>
                <Button
                  danger
                  size="small"
                  onClick={() => onDeletePipeline.mutate(row.id)}
                >
                  <i class="fa-solid fa-trash"></i>&nbsp;Delete
                </Button>
              </Flex>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PipelinesListPage;
