import { Card, Col, Row, Typography, Divider } from "antd";
import {
	CheckCircleOutlined,
	EnvironmentOutlined,
	DollarCircleOutlined,
	FileTextOutlined,
	CalendarOutlined,
} from "@ant-design/icons";
const { Text, Title } = Typography;

const JobDetailSection = ({ data }) => {
	const {
		description,
		location,
		publishDate,
		expiryDate,
		jobInfo = {},
	} = data || {};

	const { vacancies, employmentType, salary, jobType } = jobInfo;

	return (
		<Row gutter={24}>
			<Col md={18}>
				<Card
					bordered={false}
					style={{ borderRadius: 8, marginLeft: 20 }}>
					<div style={{ marginBottom: 24 }}>
						<Title
							level={5}
							style={{ fontWeight: "bold" }}>
							<FileTextOutlined style={{ marginRight: 8 }} />
							Description:
						</Title>
						<Text style={{ lineHeight: 1.6, fontSize: 16, color: "#595959" }}>
							{description}
						</Text>
					</div>
					<Divider />
					<div>
						<div style={{ marginBottom: 16 }}>
							<Text strong>
								<CheckCircleOutlined style={{ marginRight: 8 }} />
								Employment Type:
							</Text>
							<Text style={{ marginLeft: 8 }}>
								{employmentType === "partTime" ? "Part-Time" : "Contract"}
							</Text>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Text strong>
								<EnvironmentOutlined style={{ marginRight: 8 }} />
								Location:
							</Text>
							<Text style={{ marginLeft: 8 }}>{location}</Text>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Text strong>
								<CheckCircleOutlined style={{ marginRight: 8 }} />
								Vacancies:
							</Text>
							<Text style={{ marginLeft: 8 }}>{vacancies}</Text>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Text strong>
								<CheckCircleOutlined style={{ marginRight: 8 }} />
								Work Type:
							</Text>
							<Text style={{ marginLeft: 8 }}>
								{jobType === "onSite" ? "On Site" : "Remote"}
							</Text>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Text strong>
								<DollarCircleOutlined style={{ marginRight: 8 }} />
								Salary:
							</Text>
							<Text style={{ marginLeft: 8 }}>{salary ?? "N/A"}</Text>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Text strong>
								<CalendarOutlined style={{ marginRight: 8 }} />
								Publish Date:
							</Text>
							<Text style={{ marginLeft: 8 }}>{publishDate}</Text>
						</div>
						<div style={{ marginBottom: 16 }}>
							<Text strong>
								<CalendarOutlined style={{ marginRight: 8 }} />
								Expiry Date:
							</Text>
							<Text style={{ marginLeft: 8 }}>{expiryDate}</Text>
						</div>
					</div>
				</Card>
			</Col>
			<Col md={6}>this is testinasdfadsf</Col>
		</Row>
	);
};

export default JobDetailSection;
