import { useMutation, useQuery } from "@tanstack/react-query";
import {
	Button,
	Card,
	Col,
	DatePicker,
	Flex,
	Form,
	Input,
	Row,
	Select,
	Steps,
} from "antd";
import request from "../../../libs/request";

import _ from "lodash";
import { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Upload } from "antd/lib";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { PageLoading } from "@ant-design/pro-components";
import Configs from "../../../configs";
import EductionSection from "../components/form/eduction.section";
import ExperienceSection from "../components/form/experience.section";
import SkillSection from "../components/form/skill.section";

const JobApplySection = () => {
	const [activeSection, setActiveSection] = useState(null);
	const { jobId } = useParams();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({});
	const template = useQuery({
		queryKey: ["template"],
		queryFn: async () => {
			let { data } = await request.get("/form/components");

			_.remove(data, (item) => !allowedField.includes(item.name));

			return _.groupBy(data, "section");
		},
	});

	if (template.isSuccess && activeSection === null) {
		const firstSection = Object.keys(template.data)[0];
		setActiveSection(firstSection);
	}

	const onTabChange = (tabIndex) => {
		const section = Object.keys(template.data)[tabIndex];
		setActiveSection(section);
	};

	const submit = useMutation({
		mutationFn: async (form_data) => {
			const data = {
				ageRelaxationClaimed: true,
				tncAccepted: true,
				declarationAccepted: true,
				form: form_data,
			};
			const endpoint = Configs.API_BASE_URL.replace("/v1", "/v2");
			const response = await request.post(
				`${endpoint}/jobs/${jobId}/apply`,
				data
			);
			console.log("Response:", response);
			return response.data;
		},
		onSuccess: (data) => {
			navigate(`/jobs`);
		},
		onError: (error) => {
			console.error("Submission failed:", error);
		},
	});

	const onNext = (payload) => {
		const updatedFormData = { ...formData, [activeSection]: payload };
		setFormData(updatedFormData);

		const totalSectionsLength = Object.keys(template.data).length;
		const currentIndex = Object.keys(template.data).indexOf(activeSection);
		const nextSection = Object.keys(template.data)[currentIndex + 1];

		if (currentIndex + 1 === totalSectionsLength) {
			const flattenedData = {};

			Object.entries(updatedFormData).forEach(([sectionKey, sectionData]) => {
				console.log({ sectionKey });

				console.log({ sectionData });

				if (sectionKey === "education") {
					flattenedData[sectionKey] = sectionData.education.map(
						(education) => ({
							degree: education.degree,
							institute: education.institute,
							grade: education.grade,
						})
					);
				} else if (sectionKey === "experience") {
					flattenedData[sectionKey] = sectionData.professionalExperience.map(
						(experience) => ({
							title: experience.title,
							employmentType: experience.employmentType,
							company: experience.company,
							location: experience.location,
						})
					);
				} else {
					Object.entries(sectionData).forEach(([key, value]) => {
						flattenedData[key] = value;
					});
				}
			});

			console.log({ flattenedData });

			submit.mutate(flattenedData);
		} else if (nextSection) {
			setActiveSection(nextSection);
		}
	};

	const onChangeField = (section, value) => {
		setFormData((prevData) => ({
			...prevData,
			[section]: value,
		}));
	};

	const onPrevious = () => {
		const currentIndex = Object.keys(template.data).indexOf(activeSection);
		const prevSection = Object.keys(template.data)[currentIndex - 1];

		if (prevSection) {
			setActiveSection(prevSection);
		}
	};

	if (template.isLoading) return <PageLoading />;

	console.log(formData);

	return (
		<div className="container">
			<Row gutter={10}>
				<Col md={6}>
					<Card>
						<Steps
							progressDot
							initial={0}
							direction="vertical"
							// current={Object.keys(template.data).indexOf(activeSection)}
							items={Object.keys(template.data).map((entry) => ({
								title: entry,
								key: entry,
							}))}
							style={{ cursor: "default" }}
						/>
					</Card>
				</Col>
				<Col md={18}>
					<Card title={activeSection}>
						<Form
							variant="filled"
							layout="vertical"
							onFinish={onNext}
							initialValues={formData[activeSection] || {}}>
							{template.data[activeSection]?.map((field) => (
								<>
									<Form.Item
										name={field.name}
										key={`field-${field.name}`}
										label={field.label}
										rules={[
											{
												required: field.required || false,
												message: `${field.label} is required`,
											},
										]}>
										{field.type === "date" ? (
											<DatePicker
												placeholder={field.placeholder}
												required={field.required}
												type={field.type}
											/>
										) : field.type === "file" ? (
											<Upload
												placeholder={field.placeholder}
												required={field.required}
												type={field.type}
												accept={field.accept}
												maxCount={field.maxFiles}>
												<Button icon={<UploadOutlined />}>
													Click to Upload
												</Button>
											</Upload>
										) : field.type === "select" ? (
											<Select
												options={field.options}
												placeholder={field.placeholder}
											/>
										) : field.type === "textarea" ? (
											<TextArea
												variant="filled"
												placeholder={field.placeholder}
												required={field.required}
												type={field.type}
												maxLength={field.maxLength}
											/>
										) : field.type == "list" && field.name == "education" ? (
											<EductionSection />
										) : field.type == "list" &&
										  field.name == "professionalExperience" ? (
											<ExperienceSection />
										) : field.type == "list" && field.name == "skills" ? (
											<SkillSection
												onChange={(tags) => onChangeField("skills", tags)}
											/>
										) : (
											<Input
												placeholder={field.placeholder}
												type={field.type}
												maxLength={field.maxLength || undefined}
												min={field.min || undefined}
												max={field.max || undefined}
											/>
										)}
									</Form.Item>
								</>
							))}

							<Flex
								justify="space-between"
								align="center">
								<Button
									type="default"
									onClick={onPrevious}
									disabled={
										Object.keys(template.data).indexOf(activeSection) === 0
									}>
									Previous
								</Button>
								<Button
									type={
										Object.keys(template.data).indexOf(activeSection) + 1 ===
										Object.keys(template.data).length
											? "primary"
											: "default"
									}
									htmlType="submit">
									{Object.keys(template.data).indexOf(activeSection) + 1 ===
									Object.keys(template.data).length
										? "Submit"
										: "Next"}
								</Button>
							</Flex>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default JobApplySection;
