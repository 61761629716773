import { useQuery } from "@tanstack/react-query";
import { Card } from "antd";
import { Table } from "antd/lib";
import pMap from "p-map";
import { useParams } from "react-router-dom";
import request from "../../../libs/request";

const MembersPage = () => {
	const { orgId } = useParams();

	const members = useQuery({
		queryKey: ["dashboard", orgId, "members"],
		queryFn: async () => {
			const response = await request.get(`/orgs/${orgId}/members`);
			return response;
		},
	});

	return (
		<Card title="Members">
			<Table
				loading={members.isLoading}
				dataSource={members?.data?.data?.members}
				columns={[
					{
						title: "ID",
						dataIndex: "userId",
					},
					{
						title: "Full Name",
						dataIndex: "fullName",
					},
					{
						title: "Email",
						dataIndex: "email",
					},
					{
						title: "Phone Number",
						dataIndex: "phoneNo",
					},
				]}
			/>
		</Card>
	);
};
export default MembersPage;
