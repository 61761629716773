import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import Header from "../../components/header";
import HomePage from "./index.page";
import JobsPage from "./jobs.page";
import JobViewHandler from "./jobView/_handler";

import "./style.css";

const HomeHandler = () => {
  return (
    <Layout className="home-layout">
      <Header />
      <Layout.Content
        style={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Routes>
          <Route path="" index element={<HomePage />} />
          <Route path="jobs" element={<JobsPage />} />
          <Route path="jobs/:jobId/*" element={<JobViewHandler />} />

          {/* <Route path="jobs/:jobId/apply" element={<JobApplyPage />} /> */}
        </Routes>
      </Layout.Content>
    </Layout>
  );
};

export default HomeHandler;
