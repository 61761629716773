import { PlusOutlined } from "@ant-design/icons";
import { PageHeader, PageLoading } from "@ant-design/pro-components";
import { Button, Table, Modal, Form, Input, Select, message } from "antd";
import request from "../../libs/request";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import React, { useState } from "react";

const { Option } = Select;

const OrganizationsPage = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const queryClient = useQueryClient();

	const { data, isLoading, isError } = useQuery({
		queryKey: ["sudo", "organization"],
		queryFn: async () => {
			const { data } = await request.get("/orgs");
			return data;
		},
	});

	const addOrgMutation = useMutation({
		mutationFn: async (values) => {
			const { data } = await request.post("/org", values);
			return data;
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["sudo", "organization"]);
			message.success("Organization added successfully");
			setIsModalVisible(false);
			form.resetFields();
		},
		onError: (error) => {
			message.error(`Failed to add organization: ${error.message}`);
		},
	});

	const showModal = () => {
		form.resetFields();
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		form.resetFields();
		setIsModalVisible(false);
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				addOrgMutation.mutate(values);
			})
			.catch((errorInfo) => {
				console.log("Validation failed:", errorInfo);
			});
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "orgName",
			key: "orgName",
		},
		{
			title: "Type",
			dataIndex: "orgType",
			key: "orgType",
		},
		{
			title: "Address",
			dataIndex: "orgAddress",
			key: "orgAddress",
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
		},
		{
			title: "Ministry",
			dataIndex: "ministry",
			key: "ministry",
		},
		{
			title: "Website",
			dataIndex: "website",
			key: "website",
			render: (text) => (
				<a
					href={text}
					target="_blank"
					rel="noopener noreferrer">
					{text}
				</a>
			),
		},
	];

	if (isLoading) return <PageLoading />;

	return (
		<>
			<PageHeader
				title="Organizations"
				extra={[
					<Button
						key="add-org"
						type="primary"
						icon={<PlusOutlined />}
						onClick={showModal}>
						Add New
					</Button>,
				]}
				style={{
					marginBottom: "20px",
					padding: "16px 32px",
				}}
			/>
			<Table
				loading={isLoading}
				dataSource={data?.organisations}
				columns={columns}
				rowKey="id"
				pagination={{ pageSize: 10 }}
				style={{
					margin: "0px 32px",
				}}
			/>

			<Modal
				title="Add New Organization"
				visible={isModalVisible}
				onOk={handleSubmit}
				onCancel={handleCancel}
				confirmLoading={addOrgMutation.isLoading}>
				<Form
					form={form}
					layout="vertical"
					name="add_organization_form">
					<Form.Item
						name="orgName"
						label="Organization Name"
						rules={[
							{
								required: true,
								message: "Please input the organization name!",
							},
						]}>
						<Input placeholder="Enter organization name" />
					</Form.Item>

					<Form.Item
						name="orgAddress"
						label="Organization Address"
						rules={[
							{
								required: true,
								message: "Please input the organization address!",
							},
						]}>
						<Input placeholder="Enter organization address" />
					</Form.Item>

					<Form.Item
						name="department"
						label="Department"
						rules={[
							{ required: true, message: "Please select a department!" },
						]}>
						<Select placeholder="Select department">
							<Option value="department A">Department A</Option>
							<Option value="department B">Department B</Option>
							<Option value="department C">Department C</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="ministry"
						label="Ministry"
						rules={[{ required: true, message: "Please select a ministry!" }]}>
						<Select placeholder="Select ministry">
							<Option value="ministry 1">Ministry 1</Option>
							<Option value="ministry 2">Ministry 2</Option>
							<Option value="ministry 3">Ministry 3</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="orgType"
						label="Organization Type"
						rules={[
							{
								required: true,
								message: "Please select an organization type!",
							},
						]}>
						<Select placeholder="Select organization type">
							<Option value="type 1">Type 1</Option>
							<Option value="type 2">Type 2</Option>
							<Option value="type 3">Type 3</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="website"
						label="Website"
						rules={[
							{ required: true, message: "Please input the website!" },
							{ type: "url", message: "Please enter a valid URL!" },
						]}>
						<Input placeholder="Enter organization website" />
					</Form.Item>

					<Form.Item
						name="logo"
						label="Logo URL">
						<Input placeholder="Enter logo URL (optional)" />
					</Form.Item>

					<Form.Item
						name="adminPhoneNumber"
						label="Admin Phone Number"
						rules={[
							{
								required: true,
								message: "Please input the admin Phone Number!",
							},
							{
								pattern: /^\d{11}$/,
								message: "Phone Number must be 11 digits!",
							},
						]}>
						<Input placeholder="Enter admin Phone Number" />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default OrganizationsPage;
