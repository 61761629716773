import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row } from "antd";

const ExperienceSection = () => {
	return (
		<Form.List name="professionalExperience">
			{(fields, { add, remove }) => (
				<Flex
					vertical
					gap={10}>
					{fields.map(({ key, name }) => (
						<Row
							key={key}
							gutter={10}
							style={{ position: "relative" }}>
							<Col md={12}>
								<Form.Item
									name={[name, "title"]}
									fieldId={[name, "title"]}
									label="Title">
									<Input />
								</Form.Item>
							</Col>
							<Col md={12}>
								<Form.Item
									label="Employment Type"
									fieldId={[name, "employmentType"]}
									name={[name, "employmentType"]}>
									<Input />
								</Form.Item>
							</Col>
							<Col md={12}>
								<Form.Item
									label="Company"
									fieldId={[name, "company"]}
									name={[name, "company"]}>
									<Input />
								</Form.Item>
							</Col>
							<Col md={12}>
								<Form.Item
									label="Location"
									fieldId={[name, "location"]}
									name={[name, "location"]}>
									<Input />
								</Form.Item>
							</Col>

							<Button
								icon={<DeleteOutlined />}
								onClick={() => remove(name)}
								style={{ position: "absolute", top: -10, right: 5 }}
							/>
						</Row>
					))}

					<Button
						onClick={add}
						icon={<PlusOutlined />}
						block>
						Add new Experience
					</Button>
				</Flex>
			)}
		</Form.List>
	);
};

export default ExperienceSection;
