import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";

import AuthHandler from "./pages/auth/_handler";
import HomeHandler from "./pages/home/_handler";

import DashboardHandler from "./pages/dashboard/_handler";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Init from "./pages/init";
import { ConfigProvider } from "antd";
import SuperAdminHandler from "./pages/sudo/_handler";
import SudoHandler from "./pages/sudo/_handler";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <ConfigProvider theme={{ cssVar: { key: "theme" }, hashed: false }}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="*" element={<Init />}>
              <Route path="auth/*" element={<AuthHandler />} />
              <Route
                path="dashboard/org-:orgId/*"
                element={<DashboardHandler />}
              />
              <Route path="sudo/*" element={<SudoHandler />} />

              <Route path="*" element={<HomeHandler />} />
            </Route>
          </Routes>
        </Router>
      </QueryClientProvider>
    </ConfigProvider>
  );
};
export default App;
