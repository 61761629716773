import { Avatar, Card, Flex, Tag, Typography } from "antd";
import moment from "moment/moment";

const JobCard = (props) => {
  const { jobTitle, publishDate, employmentType, vacancies } = props;

  return (
    <Card className="job-card" onClick={props.onClick}>
      <Flex gap={10} justify="space-between">
        <Avatar size={40}>Govt</Avatar>
        <Flex vertical style={{ flex: "1" }}>
          <Typography.Title level={5}>{jobTitle}</Typography.Title>
          <Flex className="attributes">
            <span>Nadra</span>
            <Tag>Type: {employmentType}</Tag>
            <Tag>Seats: {vacancies}</Tag>
          </Flex>
        </Flex>
        <Flex vertical justify="center" align="center">
          <span>Islamabad, Pakistan</span>
          <span>{moment(publishDate).fromNow()}</span>
        </Flex>
      </Flex>
    </Card>
  );
};

export default JobCard;
