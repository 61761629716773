import { PageHeader } from "@ant-design/pro-components";
import { useQuery } from "@tanstack/react-query";
import { Col } from "antd";
import { Card, List, Row } from "antd/lib";
import { useNavigate } from "react-router-dom";
import request from "../../libs/request";
import JobCard from "./components/job.card";

const JobsPage = () => {
  const navigate = useNavigate();

  const jobsList = useQuery({
    queryKey: ["home", "jobs"],
    queryFn: async () => {
      const { data } = await request.get("/home/jobs");
      return data;
    },
  });

  return (
    <>
      <PageHeader title="Find Your Dream Jobs">
        Looking for jobs? Browse our latest job openings to view & apply to the
        best job today
      </PageHeader>

      <div className="container">
        <Row gutter={10}>
          <Col md={6}>
            <Card title="Job Type"></Card>
          </Col>
          <Col md={18}>
            {/* <Flex justify="center" align="center">
              <Input size="large" placeholder="Search job title or keyword" />
              <Input size="large" placeholder="City / Country" />
              <Button>Search</Button>
            </Flex> */}
            <List
              style={{ gap: "1em" }}
              dataSource={jobsList.data}
              loading={jobsList.isLoading}
              renderItem={(row) => (
                <JobCard
                  {...row}
                  onClick={() => navigate(`/jobs/${row.jobId}`)}
                />
              )}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default JobsPage;
