const API_VERSION = "v1";

let JOB_APP_URL = null
let API_BASE_URL = null

const env = process.env.REACT_APP_ENV;

if (env == "dev" ) {  
   JOB_APP_URL = "https://dev.jobapp.pk";
   API_BASE_URL = `https://api.dev.jobapp.pk/${API_VERSION}`;
}

if (env == "staging" ) {  
   JOB_APP_URL = "https://preview.jobapp.pk";
   API_BASE_URL = `https://api.preview.jobapp.pk/${API_VERSION}`;
}

if (env == "prod" ) {  
   JOB_APP_URL = "https://jobapp.pk";
   API_BASE_URL = `https://api.jobapp.pk/${API_VERSION}`;
}

const Configs = {
  API_BASE_URL,
  JOB_APP_URL,
};

export default Configs;
