import { useContext } from "react";
import { Card, Typography } from "antd";
import JobViewContext from "./context";
const { Text } = Typography;

const JobDetailPage = () => {
	const jobDetail = useContext(JobViewContext);

	const {
		jobTitle,
		description,
		location,
		publishDate,
		expiryDate,
		jobInfo = {},
	} = jobDetail || {};

	const { vacancies, employmentType, salary, jobType } = jobInfo;

	return (
		<div>
			<Card>
				<div>
					<div style={{ marginBottom: 16 }}>
						<Text strong>Title: </Text>
						<Text>{jobTitle}</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Description: </Text>
						<Text>{description}</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Employment Type: </Text>
						<Text>
							{employmentType === "partTime" ? "Part-Time" : "Contract"}
						</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Location: </Text>
						<Text>{location}</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Vacancies: </Text>
						<Text>{vacancies}</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Work Type: </Text>
						<Text>{jobType === "onSite" ? "On Site" : "Remote"}</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Salary: </Text>
						<Text>{salary ?? "N/A"}</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Publish Date: </Text>
						<Text>{publishDate}</Text>
					</div>

					<div style={{ marginBottom: 16 }}>
						<Text strong>Expiry Date: </Text>
						<Text>{expiryDate}</Text>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default JobDetailPage;
