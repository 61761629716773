import { DashboardFilled, DashboardOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { Nav } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { orgId } = useParams();
  const basePath = `/dashboard/org-${orgId}`;

  return (
    <div className="sidebar">
      <Nav className="flex-column">
        <Link
          to={basePath}
          className="nav-link"
          data-active={pathname == basePath}
        >
          <i class="fa-solid fa-table-columns"></i>Dashboard
        </Link>
        <Link
          to={basePath + "/jobs"}
          className="nav-link"
          data-active={pathname.startsWith(basePath + "/jobs")}
        >
          <i class="fa-solid fa-suitcase"></i>Jobs
        </Link>

        <Link
          to={basePath + "/pipelines"}
          className="nav-link"
          data-active={pathname.startsWith(basePath + "/pipelines")}
        >
          <i class="fa-solid fa-suitcase"></i>Pipelines
        </Link>

        <Link
          to={basePath + "/calendar"}
          className="nav-link"
          data-active={pathname.startsWith("/calendar")}
        >
          <i class="fa-regular fa-calendar"></i>Calendar
        </Link>
        <Link
          to={basePath + "/messages"}
          className="nav-link"
          data-active={pathname.startsWith("/messages")}
        >
          <i class="fa-regular fa-message"></i>Messages
        </Link>
      </Nav>

      <Nav className="flex-column">
        <Link
          to={basePath + "/settings"}
          name="Settings"
          className="nav-link"
          data-active={pathname.startsWith(basePath + "/settings")}
        >
          <i class="fa-sharp fa-solid fa-gear"></i>Manage
        </Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
