import React from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PageHeader } from "@ant-design/pro-components";
import request from "../../../libs/request";
import PipelineEditor from "../components/pipelineEditor";
import { Button } from "antd";

const schema = yup.object({
  name: yup.string().required("Pipeline Name Required!"),
});

const PipelineCreatePage = () => {
  const pipelineRef = React.useRef(null);
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm({
    reslover: yupResolver(schema),
  });

  const onSubmit = async (payload) => {
    const { stages } = pipelineRef.current;
    const toastId = toast.loading("Creating new Pipeline...", {
      hideProgressBar: true,
    });
    try {
      const { data } = await request.post("/pipelines", {
        name: payload.name,
        stages: stages,
      });
      navigate("../");
      toast.update(toastId, {
        render: `Pipeline '${payload.name}' Created.`,
        type: "success",
        autoClose: true,
        isLoading: false,
      });
    } catch (e) {
      toast.update(toastId, {
        render: "Pipeline Creating Failed!",
        type: "error",
        autoClose: true,
        isLoading: false,
      });
    }
  };

  return (
    <div className="page">
      <PageHeader
        onBack={() => navigate(-1)}
        title="Create new Pipeline"
        extra={
          <>
            <Button
              disabled={formState.isSubmitting || !formState.isValid}
              onClick={handleSubmit(onSubmit)}
              type="primary"
            >
              Save
            </Button>
          </>
        }
      />
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <Form.Group className="mb-2">
            <Form.Control placeholder="Pipeline Name" {...register("name")} />
            {formState.errors.name && (
              <Form.Text className="text-danger">
                {formState.errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          <PipelineEditor ref={pipelineRef} />
        </div>
      </div>
    </div>
  );
};

export default PipelineCreatePage;
