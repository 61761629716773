import { Rings } from "react-loader-spinner";

const Loader = (props) => {
  return (
    <Rings
      height="80"
      width="80"
      color="#519ed7"
      radius="6"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="rings-loading"
      {...props}
    />
  );
};

export default Loader;
