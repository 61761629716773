import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactSelect from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import request from "../../../libs/request";
import pMap from "p-map";

const scoreCardSchema = yup.object({
  title: yup.string().required("Title is required"),
  total: yup.number().required("Total Scrore is required"),
});

const PipelineEditor = React.forwardRef((props, ref) => {
  const [stages, setStages] = React.useState(props.stages || []);
  React.useImperativeHandle(ref, () => {
    return { stages };
  }, [stages]);

  const onAddStage = () => {
    let newStages = [...stages];
    newStages.push({ name: "untitled", scoreCards: [], assignees: [] });
    setStages(newStages);
  };

  const onStageRemove = (index) => {
    let newStages = [...stages];
    newStages.splice(index, 1);
    setStages(newStages);
  };

  const onStageUpdate = (stage, index) => {
    let newStages = [...stages];
    newStages[index] = { ...newStages[index], ...stage };
    setStages(newStages);
  };

  return (
    <div className="pipeline-editor">
      <div className="stages">
        {stages.map((entry, index) => (
          <StageItem
            {...entry}
            onRemove={() => onStageRemove(index)}
            onUpdate={(stage) => onStageUpdate(stage, index)}
          />
        ))}
      </div>
      <button className="btn btn-block btn-primary" onClick={onAddStage}>
        <i class="fa-solid fa-plus" />
        &nbsp;Add Stage
      </button>
    </div>
  );
});

const StageItem = (props) => {
  const { orgId } = useParams();

  const members = useQuery({
    queryKey: ["dashboard", orgId, "members"],
    queryFn: async () => {
      const { data } = await request.get(`/orgs/${orgId}/members`);
      const members = await pMap(
        data.members,
        async (member) => {
          const { data: profile } = await request.get(`/user/${member.userId}`);
          return { ...member, fullName: profile.fullName };
        },
        { concurrency: 3 },
      );
      return members;
    },
  });

  // const { members } = useSelector((store) => store.organization);
  const [scoreCardModalVisible, setScoreCardModalVisible] =
    React.useState(false);
  const { register, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(scoreCardSchema),
  });

  /// Add Score Card
  const onScoreAdd = (payload) => {
    let newScoreCards = [...props.scoreCards];
    newScoreCards.push(payload);
    props.onUpdate({ scoreCards: newScoreCards });
    setScoreCardModalVisible(false);
    reset();
  };

  /// Delete Score Card
  const onDeleteScoreCard = (index) => {
    let newScoreCards = [...props.scoreCards];
    newScoreCards.splice(index, 1);
    props.onUpdate({ scoreCards: newScoreCards });
  };

  return (
    <div className="stage">
      <div class="header">
        <input
          class="form-control input-sm name"
          value={props.name}
          onChange={({ target }) => props.onUpdate({ name: target.value })}
        />
        <button
          class="btn btn-default text-danger btn-sm remove-stage-btn"
          onClick={props.onRemove}
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      </div>
      <div className="body">
        <div className="mt-3">
          <h6 style={{ fontSize: ".9rem" }}>Score Cards:</h6>
          <div className="score-cards-list">
            {props.scoreCards.map((entry, index) => (
              <div className="score-card">
                <div className="score">{entry.total}</div>
                <h6 className="title">{entry.title}</h6>
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => onDeleteScoreCard(index)}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
            ))}
            <button
              className="btn btn-sm"
              onClick={() => setScoreCardModalVisible(true)}
            >
              <i class="fa-solid fa-plus" />
              &nbsp; Add Score Card
            </button>
          </div>
        </div>

        {JSON.stringify(members.data)}

        <h6 style={{ fontSize: ".9rem", marginTop: "1rem" }}>Assignees:</h6>
        <ReactSelect
          onChange={(value) =>
            props.onUpdate({ assignees: value.map((e) => e.value) })
          }
          isLoading={members.isLoading}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Search..."
          isMulti={true}
          options={members.data.map((user) => {
            return {
              value: user.userId,
              label: user.fullName,
            };
          })}
        />
      </div>

      {scoreCardModalVisible == true && (
        <Form>
          <Modal
            show={true}
            centered
            onHide={() => setScoreCardModalVisible(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>New Score Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-2">
                <Form.Label>Title</Form.Label>
                <Form.Control {...register("title")} />
                {formState.errors.title && (
                  <Form.Text className="text-danger">
                    {formState.errors.title.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Total Score</Form.Label>
                <Form.Control type="number" {...register("total")} />
                {formState.errors.total && (
                  <Form.Text className="text-danger">
                    {formState.errors.total.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={handleSubmit(onScoreAdd)}
                disabled={!formState.isValid}
                className="btn btn-primary"
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </div>
  );
};

export default PipelineEditor;
