import React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import request from "../../../libs/request";
import { Table, Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";

const JobsListPage = () => {
  const navigate = useNavigate();
  const { orgId } = useParams();

  const jobsList = useQuery({
    queryKey: ["dashboard", orgId, "jobs"],
    queryFn: async () => {
      const { data } = await request.get(`/orgs/${orgId}/jobs`);
      return data.body;
    },
  });

  return (
    <div className="page">
      <PageHeader
        onBack={() => navigate(-1)}
        title="Jobs"
        extra={
          <Button type="primary" onClick={() => navigate("create")}>
            <i class="fa-regular fa-plus" />
            &nbsp;Post new Job
          </Button>
        }
      />
      <Table
        columns={[
          {
            title: "Title",
            dataIndex: "jobTitle",
          },
          {
            title: "Type",
            dataIndex: "employmentType",
          },
          {
            title: "Vacancies",
            dataIndex: "vacancies",
          },

          {
            title: "",
            render: (row) => (
              <Button onClick={() => navigate(row.jobId)}>View</Button>
            ),
          },
        ]}
        onClick={console.log}
        loading={jobsList.isLoading}
        dataSource={jobsList.data}
      />
    </div>
  );
};

export default JobsListPage;
