import { PageHeader } from "@ant-design/pro-components";

const DashboardHomePage = () => {
  return (
    <div className="page">
      <PageHeader title="Dashboard" />
    </div>
  );
};

export default DashboardHomePage;
