import { PageHeader } from "@ant-design/pro-components";
import { Tabs } from "antd/lib";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import MembersPage from "./members.page";
const SettingsHandler = () => {
  const { orgId } = useParams();
  const basePath = `/dashboard/org-${orgId}/settings`;
  const navigate = useNavigate();

  return (
    <div class="page">
      <PageHeader
        onBack={() => navigate(-1)}
        title="Manage Organization"
        footer={
          <Tabs
            onChange={navigate}
            items={[
              { label: "Detail", key: basePath },
              { label: "Members", key: basePath + "/members" },
              { label: "Roles", key: basePath + "/roles" },
            ]}
          />
        }
      />
      <Routes>
        <Route path="/members" element={<MembersPage />} />
      </Routes>
    </div>
  );
};

export default SettingsHandler;
