import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row } from "antd";

const EductionSection = () => {
	return (
		<Form.List name="education">
			{(fields, { add, remove }) => (
				<Flex
					vertical
					gap={10}>
					{fields.map(({ key, name }) => (
						<Row
							key={key}
							gutter={10}
							style={{ position: "relative" }}>
							<Col md={12}>
								<Form.Item
									name={[name, "degree"]}
									fieldId={[name, "degree"]}
									label="Degree">
									<Input />
								</Form.Item>
							</Col>
							<Col md={12}>
								<Form.Item
									label="Institute"
									fieldId={[name, "institute"]}
									name={[name, "institute"]}>
									<Input />
								</Form.Item>
							</Col>
							<Col md={8}>
								<Form.Item
									label="Grade"
									fieldId={[name, "grade"]}
									name={[name, "grade"]}>
									<Input />
								</Form.Item>
							</Col>

							<Button
								icon={<DeleteOutlined />}
								onClick={() => remove(name)}
								style={{ position: "absolute", top: -10, right: 5 }}
							/>
						</Row>
					))}

					<Button
						onClick={add}
						icon={<PlusOutlined />}
						block>
						Add new Eduction
					</Button>
				</Flex>
			)}
		</Form.List>
	);
};

export default EductionSection;
