import React from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import DashboardApis from "../../apis";
import { PageHeader } from "@ant-design/pro-components";
import Loader from "../../components/loader";

import JobViewContext from "./context";
import { Tabs } from "antd";
import request from "../../../../libs/request";
import ApplicationsPage from "./applications.page";
import JobDetailPage from "./detail.page";

const JobViewHandler = () => {
  const { orgId, jobId } = useParams();
  const navigate = useNavigate();

  const basePath = `/dashboard/org-${orgId}/jobs/${jobId}`;

  const { data: jobDetail, isLoading } = useQuery({
    queryKey: ["dashboard", orgId, "jobs", jobId],
    queryFn: async () => {
      const { data } = await request.get(`/orgs/${orgId}/jobs/${jobId}`);
      return data;
    },
  });

  if (isLoading)
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );

  return (
		<div className="page">
			<PageHeader
				onBack={() => navigate("../jobs")}
				title={jobDetail?.jobTitle}
				footer={
					<Tabs
						onChange={navigate}
						items={[
							{ label: "Applications", key: basePath },
							{ label: "Detail", key: basePath + "/detail" },
						]}
					/>
				}
			/>

			<JobViewContext.Provider value={jobDetail}>
				<Routes>
					<Route
						path=""
						index
						element={<Navigate to="applications" />}
					/>
					<Route
						path="/applications"
						element={<ApplicationsPage />}
					/>
					<Route
						path="/detail"
						element={<JobDetailPage />}
					/>
				</Routes>

				{/* <Routes>
        <Route path="" element={<Navigate to="detail" replace />} />
        <Route path="dropped" element={<JobDroppedCandidatesPage />} />
        <Route path="candidates" element={<JobViewBoard />} />
        <Route path="detail" element={<JobDetailPage />} />
      </Routes> */}
			</JobViewContext.Provider>
		</div>
	);
};

export default JobViewHandler;
