import { Route, Routes } from "react-router-dom";
import LoginPage from "./login.page";
import SignupPage from "./signup.page";

import "./style.css";

const AuthHandler = () => {
  return (
    <div className="auth-layout">
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
      </Routes>
    </div>
  );
};

export default AuthHandler;
