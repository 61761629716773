import { Form } from "react-bootstrap";

import { Button, Form as AntForm, Input, Select, Typography } from "antd";

import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import CreatableSelect from "react-select/creatable";
import CITIES_LIST from "../../../data/cities";
import request from "../../../libs/request";
import React from "react";

import * as yup from "yup";
import { toast } from "react-toastify";

import FieldError from "../components/fieldError";
// import { pipelinesFetch } from "../../store/actions/pipelines";
import PipelineEditor from "../components/pipelineEditor";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Card, Row, Col, Steps } from "antd";
import { useState } from "react";
import { Flex, message } from "antd/lib";
import { PageHeader } from "@ant-design/pro-components";
import DashboardApis from "../apis";

const JobCreatePage = () => {
  const queryClient = useQueryClient();
  const [activeSection, setActiveSection] = useState(0);
  const navigate = useNavigate();

  const { orgId } = useParams();

  const [formData, setFormData] = React.useState({
    detail: {
      vacancies: 1,
    },
    requirments: {},
    pipeline: {},
  });

  const onCreateJob = useMutation({
    mutationFn: async () => {
      const payload = {
        ...formData.detail,
        orgId: orgId,
        pipeline: formData.pipeline,
      };
      return request.post(`/orgs/${orgId}/jobs`, payload);
    },
    onSuccess: () => {
      message.success("Job posted Succesfully");
      navigate("../jobs");
    },
    onError: () => message.error("Job posting failed"),
  });

  return (
    <div className="page">
      <PageHeader title="Post new Job" onBack={() => navigate(-1)} />
      <Row gutter={0}>
        <Col md={5}>
          <Card>
            <Steps
              progressDot
              initial={activeSection}
              direction="vertical"
              items={[
                { key: "detail", title: "Detail" },
                // { key: "requirments", title: "Requirments" },
                { key: "pipeline", title: "Pipeline" },
                { key: "review", title: "Review" },
              ]}
            />
          </Card>
        </Col>
        <Col md={19}>
          {activeSection === 0 && (
            <DetailSection
              initialValues={formData.detail}
              onSubmit={(data) => {
                setFormData({ ...formData, detail: data });
                setActiveSection(1);
              }}
            />
          )}

          {activeSection === 1 && (
            <PipelineSection
              initialVazlues={formData.pipeline}
              onBack={() => setActiveSection(0)}
              onSubmit={(pipeline) => {
                setFormData({ ...formData, pipeline });
                setActiveSection(2);
              }}
            />
          )}

          {activeSection === 2 && (
            <Card title="Review">
              <Typography.Title>Rview</Typography.Title>

              <Flex
                justify="space-between"
                align="center"
                style={{ marginTop: "1em" }}
              >
                <Button onClick={() => setActiveSection(1)}>
                  <i class="fa-solid fa-chevron-left"></i> Back&nbsp;
                </Button>
                <Button
                  type="primary"
                  loading={onCreateJob.isPending}
                  onClick={onCreateJob.mutate}
                >
                  Post&nbsp;<i class="fa-solid fa-chevron-right"></i>
                </Button>
              </Flex>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

const DetailSection = ({ initialValues, onSubmit }) => {
  return (
    <Card title="Job Detail">
      <AntForm
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSubmit}
        variant="filled"
      >
        <AntForm.Item
          label="Title"
          name="jobTitle"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </AntForm.Item>
        <AntForm.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "" }]}
        >
          <Input.TextArea rows={3} />
        </AntForm.Item>
        <Row gutter={10}>
          <Col md={8}>
            <AntForm.Item
              label="Employment Type"
              name="employmentType"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                options={[
                  { label: "Part-Time", value: "partTime" },
                  { label: "Contract", value: "contract" },
                ]}
              />
            </AntForm.Item>
          </Col>

          <Col md={8}>
            <AntForm.Item
              label="Location"
              name="location"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                showSearch={true}
                options={CITIES_LIST.map((entry) => {
                  return { label: entry, value: entry };
                })}
              />
            </AntForm.Item>
          </Col>
          <Col md={8}>
            <AntForm.Item
              label="Vacancies"
              name="vacancies"
              rules={[{ required: true, message: "" }]}
            >
              <Input type="number" min={1} max={100} />
            </AntForm.Item>
          </Col>

          <Col md={8}>
            <AntForm.Item
              label="Work"
              name="jobType"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                options={[
                  { label: "On Site", value: "onSite" },
                  { label: "Remote", value: "remote" },
                ]}
              />
            </AntForm.Item>
          </Col>
          <Col md={8}>
            <AntForm.Item label="Salary" name="salary">
              <Input />
            </AntForm.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={8}>
            <AntForm.Item
              label="Publish Date"
              name="publishDate"
              rules={[{ required: true, message: "" }]}
            >
              <Input type="date" />
            </AntForm.Item>
          </Col>
          <Col md={8}>
            <AntForm.Item
              label="Expiry Date"
              name="expiryDate"
              rules={[{ required: true, message: "" }]}
            >
              <Input type="date" />
            </AntForm.Item>
          </Col>
        </Row>
        <Flex justify="space-between" align="center">
          <span />
          <Button htmlType="submit" type="primary">
            Next&nbsp;<i class="fa-solid fa-chevron-right"></i>
          </Button>
        </Flex>
      </AntForm>
    </Card>
  );
};

const PipelineSection = (props) => {
  const { orgId } = useParams();
  const { data: pipelines, isLoading } = useQuery({
    queryKey: ["dashboard", orgId, "pipelines"],
    queryFn: async () => {
      const { data } = await request.get(`/orgs/${orgId}/pipelines`);
      return data.pipelines || [];
    },
  });

  const [pipelineIndex, setPiplineIndex] = React.useState(null);
  const pipelineRef = React.useRef(null);
  if (isLoading) return <span>Loading....</span>;

  return (
    <Card
      title="Pipeline"
      extra={
        <Select
          style={{ width: "15rem" }}
          onChange={(index) => setPiplineIndex(index)}
          options={pipelines.map((entry, index) => {
            return { label: entry.name, value: index };
          })}
        />
      }
    >
      <Flex vertical justify="center">
        {pipelineIndex == null && (
          <Typography.Title
            level={5}
            style={{ textAlign: "center" }}
            type="secondary"
          >
            Select a Pipeline
          </Typography.Title>
        )}

        {pipelineIndex !== null && (
          <PipelineEditor
            ref={pipelineRef}
            stages={pipelines[pipelineIndex].stages}
          />
        )}
      </Flex>

      <Flex justify="space-between" align="center" style={{ marginTop: "1em" }}>
        <Button onClick={props.onBack}>
          <i class="fa-solid fa-chevron-left"></i> Back&nbsp;
        </Button>
        <Button
          type="primary"
          onClick={() =>
            props.onSubmit({
              name: pipelines[pipelineIndex].name,
              stages: pipelineRef.current.stages,
            })
          }
        >
          Next&nbsp;<i class="fa-solid fa-chevron-right"></i>
        </Button>
      </Flex>
    </Card>
  );
};

export default JobCreatePage;
