import { Layout, Menu } from "antd";
import Header from "../../components/header";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { Route, Routes, useNavigate } from "react-router-dom";
import OrganizationsPage from "./organizations.page";

const SudoHandler = () => {
  const navigate = useNavigate();
  const basePath = "/sudo";

  return (
    <Layout
      className="sudo-layout"
      style={{ display: "flex", flexDirection: "column", height: "100vh" }}
    >
      <Header />

      <Layout style={{ flex: 1 }}>
        <Sider theme="light">
          <Menu
            onClick={({ key }) => navigate(key)}
            theme="light"
            items={[
              {
                label: "Organizations",
                key: basePath + "/organizations",
              },
            ]}
          />
        </Sider>
        <Content style={{ overflowY: "scroll" }}>
          <Routes>
            <Route path="/organizations" element={<OrganizationsPage />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SudoHandler;
