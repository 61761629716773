import { useState } from "react";
import { Input, Tag } from "antd";

const SkillSection = ({ onChange }) => {
	const [tags, setTags] = useState([]);
	const [inputValue, setInputValue] = useState("");

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = () => {
		const newTag = inputValue.trim();
		if (newTag && !tags.includes(newTag)) {
			const updatedTags = [...tags, newTag];
			setTags(updatedTags);
			setInputValue("");
			onChange(updatedTags); // Notify parent with the updated tags
		}
	};

	const handleTagClose = (removedTag) => {
		const updatedTags = tags.filter((tag) => tag !== removedTag);
		setTags(updatedTags);
		onChange(updatedTags); // Notify parent with the updated tags
	};

	return (
		<div>
			<div style={{ marginBottom: 16 }}>
				{tags.map((tag, index) => (
					<Tag
						key={index}
						closable
						onClose={() => handleTagClose(tag)}>
						{tag}
					</Tag>
				))}
			</div>
			<Input
				type="text"
				size="large"
				value={inputValue}
				onChange={handleInputChange}
				onPressEnter={handleInputConfirm}
				placeholder="Press Enter to add tags"
			/>
		</div>
	);
};

export default SkillSection;
