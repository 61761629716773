import { end } from "@popperjs/core";
import {
  Col,
  Flex,
  Modal,
  Radio,
  Row,
  Space,
  Tabs,
  Timeline,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useContext } from "react";
import JobViewContext from "./context";

import _ from "lodash";

const ApplicationViewModal = ({ application, onClose }) => {
  const { pipeline } = useContext(JobViewContext);

  const [activeSection, setActiveSection] = useState("detail");

  return (
    <Modal
      open={true}
      footer={null}
      onClose={onClose}
      onCancel={onClose}
      width={"60%"}
      title={`Application #${application.appId}`}
    >
      <Tabs
        defaultActiveKey="detail"
        items={[
          {
            label: "Detail",
            key: "detail",
            children: <DetailSection application={application} />,
          },
          {
            label: "History",
            key: "history",
            children: <HistorySection application={application} />,
          },
        ]}
        onChange={setActiveSection}
      />
    </Modal>
  );
};

const DetailSection = () => {
  return <h1>History Section</h1>;
};

const HistorySection = ({ application }) => {
  const { pipeline } = useContext(JobViewContext);
  const { stages } = application;
  return (
    <Timeline
      items={stages.map((entry) => {
        const stageDetail = _.find(pipeline.stages, { id: entry.id });

        return {
          children: (
            <Flex vertical>
              <Typography.Title level={5} style={{ marginBottom: "0px" }}>
                {stageDetail.name}
              </Typography.Title>
              <span>
                {moment(entry.startedAt).format("YYYY-MM-DD hh:mm:ssA")}
              </span>
            </Flex>
          ),
        };
      })}
    />
  );
};

export default ApplicationViewModal;
