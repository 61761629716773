import { Form } from "react-bootstrap";

const FieldError = ({ error }) => {
  if (error)
    return (
      <Form.Text className="text-danger" style={{ fontWeight: 500 }}>
        * {error.message}
      </Form.Text>
    );
  return <></>;
};
export default FieldError;
