import axios from "axios";
import Configs from "../configs";

const request = axios.create({});

request.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("jobapp.auth.token");
    // if (!token) throw new Error("Auth Token missing");
    config.baseURL = Configs.API_BASE_URL;

    // if (config?.excludeOrgId !== true) {
    //   const orgId = localStorage.getItem("orgId");
    //   if (!orgId) throw new Error("Organization ID missing");
    //   config.baseURL = `${config.baseURL}/orgs/${orgId}`;
    // }
    //
    config.headers = {
      ...config.headers,
      authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

export default request;
